

export default {
  name: "CleanTaskLogList",
  components: {
  },
  props: {
  },
  mounted() {
    let t = this;
  },
  created() {
    let t = this;
    let d = new Date();
    d.setDate(d.getDate() - 1);
    t.defaultDate = d;
    t.historyDate = d.format('yyyy-MM-dd');
    t.loadStats();
    t.loadData();
  },
  activated: function (el) {
  },
  deactivated() {
  },
  methods: {

    loadStats() {
      let t = this;

      axios.get('cleanTaskLog/stats').then(res => {
        if (res.data.status == 1) {
          t.stats = res.data.options.stats;
        }
      });

      axios.get('room?sample=name').then(res => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;
          t.list.room = arr;
          t.dict.room = listToDict(t.list.room);
        }
      });

    },

    loadData() {
      let t = this;

      let p = {
        '_with': 'order,task',
        'status': t.type == 4 ? 1 : 0,
      };

      if (t.type == 4) {
        p.confirmed_at = t.historyDate + ',' + t.historyDate
      }
      else{
        p.type = t.type;
      }

      axios.get('cleanTaskLog', { params: p }).then(res => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;
          arr.forEach(item => {
            item.selected = false;
          });
          t.list.log = arr;
        }
      });

    },
    initUI() {

    },
    confirm() {

      let t = this;

      let ids = [];
      let items = [];

      t.list.log.forEach(item => {
        if (item.selected) {
          ids.push(item.id);
          items.push(item);
        }
      });

      if (ids.length == 0) {
        A.err('少なくとも1つの清掃作業の確認');
        return;
      }

      axios.post('cleanTaskLog/batchConfirm', { ids: ids.join(',') }).then(res => {
        if (res.data.status == 1) {
          items.forEach(item => {
            t.list.log.remove(item);
          });
          t.loadStats();
        }
      });

    },

    onSelectDate(d) {

      let t = this;

      let date = d.format('yyyy-MM-dd');

      if (t.historyDate == date)
        return;

      t.historyDate = date;

      t.showDatePopover = false;

      t.loadData();
    },

    minusDate() {
      let t = this;

      let d = new Date(t.historyDate.replace(/-/g, '/'));

      d.setDate(d.getDate() - 1);

      t.historyDate = d.format('yyyy-MM-dd');

      t.defaultDate = d;

      t.loadData();

      t.stopPropagation();
    },

    plusDate() {
      let t = this;

      let d = new Date(t.historyDate.replace(/-/g, '/'));

      d.setDate(d.getDate() + 1);

      t.defaultDate = d;

      t.historyDate = d.format('yyyy-MM-dd');

      t.loadData();

      t.stopPropagation();
    },

    scrollToDate(date) {
      this.$refs.calendar.scrollToDate(date);
    },
  },
  data() {
    return {
      defaultDate: null,
      historyDate: null,
      showDatePopover: false,
      maxDate: new Date(),
      minDate: new Date('2023/10/15'),
      type: 2,
      stats: {
        new: 0,
        cancel: 0,
        update: 0,
      },
      list: {
        log: [],
        room: []
      },
      dict: {
        room: {}
      }
    };
  },
  computed: {
  }
};
