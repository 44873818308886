
import colSorter from '../components/col-sorter.vue';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

export default {
    name: 'analysis',
    components: {
        colSorter
    },
    created() {

        echarts.use([
            TooltipComponent,
            GridComponent,
            LineChart,
            CanvasRenderer,
            UniversalTransition
        ]);

        let t = this;

        document.body.classList.add('pc');

        let days = 7;

        t.startDate = Date.today();

        t.endDate = Date.today();

        t.endDate.setDate(t.endDate.getDate() + days);

        t.defaultDates = [t.startDate, t.endDate];

        for (let i = 1; i <= 17; i++) {
            t.list.bedroom.push({
                'name': i + 'R',
                'value': i,
                'selected': true
            });
        }

        t.list.bedroom.push({
            'name': 'R',
            'value': 0,
            'selected': true
        });


        let arr = [
            {
                'name': '民泊',
                'value': 'house',
            },
            {
                'name': 'アパート',
                'value': 'apartment'
            },
            {
                'name': 'コテージ',
                'value': 'cottage'
            },
            {
                'name': 'バンガロー',
                'value': 'bungalow'
            },
            {
                'name': 'ヴィラ (vīra)',
                'value': 'villa'
            },
            {
                'name': 'ホリデービレッジ',
                'value': 'holiday village'
            },
            {
                'name': 'シャレー',
                'value': 'chalet'
            },
            {
                'name': 'ホテル',
                'value': 'hotel'
            },
            {
                'name': 'Other',
                'value': 'other'
            }
        ];

        arr.forEach(item => {
            item.selected = true;
        });

        t.list.type = arr;

        t.dict.type = listToDict(t.list.type);

        t.loadTaskList();

        if (!window.google || !window.google.maps) {
            let src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBmM62bnkKj7SvAsQ5X8VmsY9AZ0gAsA38&libraries=geometry,marker';
            let s = document.createElement('script');
            s.src = src;
            document.body.appendChild(s);

            window.mapInterval = setInterval(function () {
                if (window.google && window.google.maps) {
                    window.clearInterval(window.mapInterval);
                    t.initMap();
                }
            }, 100);
        }
        else {
            t.initMap();
        }

        window.openMPDetails = function () {
            t.openMPDetails(window.__MP);
        }
    },
    activated() {

        let t = this;

    },
    methods: {

        openMPDetails(_mp) {

            let t = this;

            t.startDate2 = new Date(t.startDate.valueOf());
            t.endDate2 = new Date(t.endDate.valueOf());

            t.defaultDates2 = [t.startDate2, t.endDate2];

            t.people2 = t.people;

            _mp.price2 = _mp.price;
            _mp.occupancy_rate2 = _mp.occupancy_rate;
            _mp._price_date = '';

            t.mp = _mp;

            t.loadChartData(t.mp.id);

            t.showMP = true;
        },

        loadChartData(property_id) {

            let t = this;

            let p = {
                'property_ids': property_id,
                'start_date': t.startDate2.format('yyyy-MM-dd'),
                'end_date': t.endDate2.format('yyyy-MM-dd'),
                'people': t.people2
            };

            axios.get('monitorTask/getPricesChartData', { params: p }).then(res => {
                if (res.data.status == 1) {
                    let dict = res.data.options.dict;
                    let items = dict[property_id];
                    if (items && items.length > 0) {
                        let days = items.length;
                        let countOrder = 0;
                        let maxPrice = 0;
                        items.forEach(item => {
                            if (item.price > maxPrice)
                                maxPrice = item.price;
                            if (item.has_order == 1)
                                countOrder++;
                        });

                        t.mp.price2 = maxPrice;

                        t.mp.occupancy_rate2 = countOrder / days * 100;

                        t.$forceUpdate();

                        t.initChart2('cLineChart_p1_' + property_id, items);
                    }
                }
            });

        },

        initMap() {

            let map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 34.992768274391, lng: 135.76252655443918 },
                zoom: 13,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }]
                    }
                ]
            });

            window.MAP = map;

            google.maps.event.addListener(map, 'click', function (event) {
                // 检查是否点击了标记
                if (!event.overlay) {
                    if(window.INFOWIN){
                        window.INFOWIN.close();
                    }
                }
            });
        },

        initMarkers() {

            let t = this;

            function _render() {
                let items = t.mapRender.items;

                if (!items || items.length == 0) {
                    return;
                }

                window.MARKERDICT = {};

                if (window.MC) {
                    window.MC.clearMarkers();
                }

                let map = window.MAP;

                function interpolateColor(value, minVal = 0, maxVal = 100, startColor = [64, 160, 255], endColor = [255, 0, 0]) {
                    // 计算当前值在整个区间中的比例
                    const ratio = (value - minVal) / (maxVal - minVal);

                    // 对每个颜色通道进行线性插值
                    const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * ratio);
                    const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * ratio);
                    const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * ratio);

                    return `rgb(${r}, ${g}, ${b})`;
                }

                function addMarker(item) {
                    let lat = Number(item.lat);
                    let lng = Number(item.lng);

                    let color;

                    let max = 100;

                    if (t.mapRender.mode == 'price') {
                        let price = Math.round(item.price / 1000);
                        if (price > max)
                            price = max;

                        color = interpolateColor(price, 0, max);
                    }
                    else {
                        color = interpolateColor(Number(item.occupancy_rate), 0, max);
                    }

                    const pinIcon = {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 7,
                        fillColor: color,
                        fillOpacity: 1,
                        strokeWeight: 0,
                        anchor: new google.maps.Point(7, 7)
                    };

                    let marker = new google.maps.Marker({
                        position: { lat: lat, lng: lng },
                        icon: pinIcon,
                        zIndex: 20000
                    });

                    marker.addListener('click', function () {

                        let _m = this;

                        if (window.BUSY) {
                            return;
                        }

                        if (window.INFOWIN) {
                            window.INFOWIN.close();
                            if (window.INFOWIN._m == _m) {
                                window.INFOWIN = null;
                                return;
                            }
                        }

                        let p = t._buildReqPrams();

                        p.id = item.id;

                        window.BUSY = true;

                        axios.get('monitorTask/getPropertyList/' + t.task.id, { params: p }).then(res => {

                            window.BUSY = false;

                            if (res.data.status == 1) {
                                let arr = res.data.options.items;
                                let details = arr[0];

                                if (details) {

                                    window.__MP = details;

                                    let price = Math.round(details.price / 10) / 100 + 'K';
                                    let occupancy_rate = Number(details.occupancy_rate) + '%';
                                    let content = `<div class="marker-info">
            <div class="line"><div class="label">施設名:</div> <div class="value two-row-text">${details.name}</div></div>
            <div class="line"><div class="label">部屋数:</div> <div class="value">${details.room}</div></div>
            <div class="line"><div class="label">単価:</div> <div onclick="openMPDetails()" class="value hover" style="color:#8e2de2;">${price}</div></div>
            <div class="line"><div class="label">レビュー:</div> <div class="value">${details.reviews}</div></div>
            <div class="line"><div class="label">稼働率:</div> <div class="value">${occupancy_rate}</div></div>
        </div>`;
                                    const infowindow = new google.maps.InfoWindow({
                                        content: content,
                                        maxWidth: 300
                                    });

                                    infowindow._m = _m;

                                    infowindow.open(map, marker);

                                    window.INFOWIN = infowindow;

                                    const anchorPoint = new google.maps.Point(3, 7);
                                    infowindow.setPosition({ lat: lat, lng: lng });
                                    infowindow.setOptions({ pixelOffset: new google.maps.Size(anchorPoint.x, -anchorPoint.y) });

                                }
                                else {
                                    A.err('このリストに該当する情報は見つかりませんでした。');
                                }
                            }
                        });


                    });

                    return marker;
                }

                const markers = items.map(item => addMarker(item));

                let renderer = {
                    render: function (a, b, c) {

                        // <circle cx="120" cy="120" opacity=".6" r="70" />
                        // <circle cx="120" cy="120" opacity=".3" r="90" />
                        const svg = window.btoa(`
<svg fill="rgb(255,0,0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
<circle cx="120" cy="120" opacity=".3" r="70" />
<circle cx="120" cy="120" opacity=".2" r="100" />
</svg>`);

                        return new google.maps.Marker({
                            position: a.position,
                            icon: {
                                url: `data:image/svg+xml;base64,${svg}`,
                                scaledSize: new google.maps.Size(45, 45),
                            },
                            label: {
                                text: String(a.count),
                                color: "rgba(0,0,0)",
                                fontSize: "14px",
                            },
                            // adjust zIndex to be above other markers
                            zIndex: 100 + a.count,
                        });
                    }
                };

                window.MC = new MarkerClusterer({
                    map, markers,
                    algorithm: new SuperClusterAlgorithm({
                        maxZoom: 13,
                        minZoom: 10,
                        radius: 80,
                        gridSize: 120
                    }),
                    renderer
                });
            }

            window.checkGoogle = setInterval(function () {
                if (window.google && window.google.maps) {
                    window.clearInterval(window.checkGoogle);
                    _render();
                }
            }, 100);

        },

        switchMapRenderMode() {
            let t = this;

            if (t.mapRender.mode == 'price') {
                t.mapRender.mode = 'occupancy_rate';
            }
            else if (t.mapRender.mode == 'occupancy_rate') {
                t.mapRender.mode = 'price';
            }

            t.initMarkers();
        },

        scrollToDate(date) {
            this.$refs.calendar.scrollToDate(date);
        },

        onSelectDates(dates) {
            let t = this;

            t.startDate = dates[0];
            t.endDate = dates[1];

            t.showDatePopover2 = false;

            t.loadStats();

            t.loadData();

            t.getMapData();

        },

        onSelectDates2(dates) {
            let t = this;

            t.startDate2 = dates[0];
            t.endDate2 = dates[1];

            t.defaultDates2 = [t.startDate2, t.endDate2];

            t.showDatePopover3 = false;

            t.loadChartData(t.mp.id);
        },

        loadStats() {
            let t = this;


            let p = {
                'start_date': t.startDate.format('yyyy-MM-dd'),
                'end_date': t.endDate.format('yyyy-MM-dd'),
                'distance': t.range * 1000,
                'people': t.people,
            };

            axios.get('monitorTask/getStats/' + t.task.id, { params: p }).then(res => {
                if (res.data.status == 1) {
                    t.stats = res.data.options.stats;
                }
            });

        },

        _buildReqPrams() {

            let t = this;

            let f = JSON.parse(JSON.stringify(t.filter));

            if (f.price[1] >= 110) {
                f.price = [f.price[0] * 1000, 'MAX'];
            }
            else {
                f.price[0] = f.price[0] * 1000;
                f.price[1] = f.price[1] * 1000;
            }

            if (f.reviews[1] >= 1100)
                f.reviews = [f.reviews[0], 'MAX']

            if (f.people[1] >= 21)
                f.people = [f.people[0], 'MAX']

            let p = {
                '_filter': f,
                'start_date': t.startDate.format('yyyy-MM-dd'),
                'end_date': t.endDate.format('yyyy-MM-dd'),
                'distance': t.range * 1000,
                'people': t.people,
            };

            let dict = {
                1: 'ASC',
                2: 'DESC'
            }
            for (const key in t.colSorters) {
                let v = t.colSorters[key];
                if (v != -1) {
                    p._order = key + '-' + dict[v];
                    break;
                }
            }

            return p;

        },

        loadData() {

            let t = this;

            let p = t._buildReqPrams();

            p.pageSize = t.pager.perParge;
            p.page = t.pager.page;

            t.saveConfig();

            axios.get('monitorTask/getPropertyList/' + t.task.id, { params: p }).then(res => {
                if (res.data.status == 1) {
                    let arr = res.data.options.items;
                    let ids = [];
                    arr.forEach(item => {
                        ids.push(item.id);
                    });
                    t.list.item = arr;

                    t.pager.total = res.data.options.itemTotal;

                    let p = {
                        'property_ids': ids.join(','),
                        'start_date': t.startDate.format('yyyy-MM-dd'),
                        'end_date': t.endDate.format('yyyy-MM-dd'),
                        'people': t.people
                    };

                    setTimeout(function () {
                        axios.get('monitorTask/getPricesChartData', { params: p }).then(res => {
                            if (res.data.status == 1) {
                                let dict = res.data.options.dict;

                                for (const id in dict) {
                                    if (id && dict[id])
                                        t.initChart('cLineChart_' + id, dict[id]);
                                }
                            }
                        });
                    }, 100);

                    // t.openMPDetails(t.list.item[6]);
                }
            });

        },

        expoertCSV() {
            let t = this;
            let p = t._buildReqPrams();
            p._export = 1;
            p = buildURLParams(p);

            let url = axios.defaults.baseURL + 'monitorTask/getPropertyList/' + t.task.id;

            if (p)
                url += '?' + p;

            location.href = url;

        },

        initChart(ele_id, items) {

            let dates = [];
            let prices = [];

            let maxPrice = 0;
            items.forEach(item => {
                dates.push(item.date.substr(5).replace('-', '/'));
                prices.push(item.price);
                if (item.price > maxPrice) {
                    maxPrice = item.price;
                }
            });

            let j;

            for (let i = 0; i < prices.length; i++) {

                if (prices[i] == 0) {

                    j = 1;

                    while (true) {

                        if (i + j >= prices.length)
                            break;

                        if (prices[i + j] > 0)
                            prices[i] = prices[i + j];

                        j++;
                    }

                    if (prices[i] == 0) {

                        j = 1;

                        while (true) {

                            if (i - j < 0)
                                break;

                            if (prices[i - j] > 0)
                                prices[i] = prices[i - j];

                            j++;
                        }


                    }
                }

            }

            try {


                let c = prices.length;

                let limit = 31;

                if (c > limit) {
                    let offset = Math.ceil(c / limit);

                    let _dates = [];
                    let _prices = [];

                    let i = 0;

                    while (true) {

                        if (i >= c) {
                            i = c - 1;
                        }

                        _dates.push(dates[i]);
                        _prices.push(_prices[i]);

                        if (i >= c - 1) {
                            break;
                        }

                        i += offset;
                    }
                }
            } catch (e) {

            }

            let chartDom = document.getElementById(ele_id);
            let myChart = echarts.init(chartDom);
            let option;

            let max = 100 * 1000;

            let yAxis = {
                show: false,
                type: 'value',
            };

            if (maxPrice <= max) {
                yAxis.min = 0;
                yAxis.max = max;

            }

            option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {

                        return (params[0].value / 1000).toFixed(1) + 'K' + '<br>' + params[0].axisValue;
                    },
                    textStyle: {
                        fontWeight: 'bold',
                        color: '#000'
                    },
                    borderColor: '#000',
                    padding: [2, 2],
                    borderRadius: 0
                },
                grid: {
                    top: ' 10%',
                    left: '2%',
                    right: '2%',
                    bottom: '10%',
                    containLabel: false,
                    height: '40px',
                    width: 'auto'
                },
                xAxis: [
                    {
                        show: false,
                        type: 'category',
                        boundaryGap: false,
                        data: dates
                    }
                ],
                yAxis: [
                    yAxis
                ],
                series: [
                    {
                        name: '',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: true,
                        symbolSize: 6,
                        itemStyle: {
                            color: '#FF4D4D'
                        },
                        areaStyle: {
                            opacity: 1,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(255, 0, 0)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(64, 160, 255)'
                                },

                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: prices
                    },

                ]
            };

            myChart.setOption(option);

        },

        initChart2(ele_id, items) {

            let t = this;

            if (window._CHART2) {
                try {
                    window._CHART2.dispose();
                }
                catch (e) {

                }
            }

            let dates = [];
            let prices = [];
            let counts = [];

            let maxPrice = 0;
            items.forEach(item => {
                item.price_history = JSON.parse(item.price_history || '{}');
                dates.push(item.date.substr(5).replace('-', '/'));
                prices.push(item.price);
                counts.push(Object.keys(item.price_history).length);

                if (item.price > maxPrice) {
                    maxPrice = item.price;
                }
            });

            let j;

            for (let i = 0; i < prices.length; i++) {

                if (prices[i] == 0) {

                    j = 1;

                    while (true) {

                        if (i + j >= prices.length)
                            break;

                        if (prices[i + j] > 0)
                            prices[i] = prices[i + j];

                        j++;
                    }

                    if (prices[i] == 0) {

                        j = 1;

                        while (true) {

                            if (i - j < 0)
                                break;

                            if (prices[i - j] > 0)
                                prices[i] = prices[i - j];

                            j++;
                        }


                    }
                }

            }

            let chartDom = document.getElementById(ele_id);
            chartDom.style.width = prices.length * 120 + 'px';
            let myChart = echarts.init(chartDom);
            let option;

            let max = 100 * 1000;

            let yAxis = {
                show: false,
                type: 'value',
            };

            if (maxPrice <= max) {
                yAxis.min = 0;
                yAxis.max = max;

            }

            option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        return (params[0].value / 1000).toFixed(1) + 'K' + '<br>' + params[0].axisValue;
                    },
                    textStyle: {
                        fontWeight: 'bold',
                        color: '#000'
                    },
                    borderColor: '#000',
                    padding: [2, 2],
                    borderRadius: 0
                },
                grid: {
                    top: ' 20%',
                    left: '60px',
                    right: '0%',
                    bottom: '10%',
                    containLabel: false,
                    height: '80px',
                    width: (prices.length - 1) * 120 + 'px',
                },
                xAxis: [
                    {
                        show: true,
                        type: 'category',
                        boundaryGap: false,
                        data: dates
                    }
                ],
                yAxis: [
                    yAxis
                ],
                series: [
                    {
                        name: '',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: true,
                        symbolSize: 12,
                        itemStyle: {
                            color: '#FF4D4D'
                        },
                        areaStyle: {
                            opacity: 1,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(255, 0, 0)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(64, 160, 255)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: prices,
                        label: {
                            show: true, // 开启显示
                            position: 'top', // 在symbol上方显示
                            formatter: function (params) {
                                return (params.value / 1000).toFixed(1) + 'K' + ' [' + counts[params.dataIndex] + ']';
                            },
                            color: '#000',
                            fontSize: 14,
                            fontWeight: 900
                        },
                    },

                ]
            };

            myChart.setOption(option);

            myChart.on('click', function (params) {
                if (params.componentType === 'series' && params.seriesType === 'line') {
                    var dataIndex = params.dataIndex;
                    let item = items[dataIndex];
                    if (item) {
                        t.mp._price_date = item.date;
                        t.$forceUpdate();
                        let dates = [];
                        let prices = [];
                        for (const date in item.price_history) {
                            let price = item.price_history[date];
                            dates.push(date.substr(5).replace('-', '/'));
                            prices.push(price);
                        }
                        if (dates.length > 0) {
                            setTimeout(function () {
                                t.initChart3('cLineChart_p2_' + t.mp.id, dates, prices);
                            }, 100);
                        }
                        else {
                            A.err('単価の推移はない');
                        }
                    }
                }
            });

            window._CHART2 = myChart;
        },

        initChart3(ele_id, dates, prices) {

            let t = this;

            let maxPrice = 0;

            prices.forEach(price => {
                if (price > maxPrice) {
                    maxPrice = price;
                }
            });

            if (window._CHART3) {
                try {
                    window._CHART3.dispose();
                }
                catch (e) {

                }
            }

            let chartDom = document.getElementById(ele_id);
            chartDom.style.width = prices.length * 120 + 'px';
            let myChart = echarts.init(chartDom);
            let option;

            let max = 100 * 1000;

            let yAxis = {
                show: false,
                type: 'value',
            };

            if (maxPrice <= max) {
                yAxis.min = 0;
                yAxis.max = max;

            }

            option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        return (params[0].value / 1000).toFixed(1) + 'K' + '<br>' + params[0].axisValue;
                    },
                    textStyle: {
                        fontWeight: 'bold',
                        color: '#000'
                    },
                    borderColor: '#000',
                    padding: [2, 2],
                    borderRadius: 0
                },
                grid: {
                    top: ' 20%',
                    left: '60px',
                    right: '0%',
                    bottom: '10%',
                    containLabel: false,
                    height: '80px',
                    width: (prices.length - 1) * 120 + 'px',
                },
                xAxis: [
                    {
                        show: true,
                        type: 'category',
                        boundaryGap: false,
                        data: dates
                    }
                ],
                yAxis: [
                    yAxis
                ],
                series: [
                    {
                        name: '',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: true,
                        symbolSize: 12,
                        itemStyle: {
                            color: '#FF4D4D'
                        },
                        areaStyle: {
                            opacity: 1,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(255, 0, 0)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(64, 160, 255)'
                                }
                            ])
                        },
                        label: {
                            show: true, // 开启显示
                            position: 'top', // 在symbol上方显示
                            formatter: function (params) {
                                return (params.value / 1000).toFixed(1) + 'K';
                            },
                            color: '#000',
                            fontSize: 14,
                            fontWeight: 900
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: prices,
                    },

                ]
            };

            myChart.setOption(option);

            window._CHART3 = myChart;
        },

        getMapData() {

            let t = this;

            let p = t._buildReqPrams();

            delete p._order;

            axios.get('monitorTask/getMapData/' + t.task.id, { params: p }).then(res => {
                if (res.data.status == 1) {
                    t.mapRender.items = res.data.options.items;
                    t.initMarkers();
                }
            });


        },

        confirmFilter() {

            let t = this;

            t.filter.room = [];
            t.filter.type = [];

            t.list.bedroom.forEach(item => {
                if (item.selected) {
                    t.filter.room.push(item.value);
                }
            });

            t.list.type.forEach(item => {
                if (item.selected) {
                    t.filter.type.push(item.value);
                }
            });

            t.showFilterPopover = false;

            t.loadData();

            t.getMapData();
        },

        onRangeChange() {

            let t = this;

            t.pager.page = 1;

            t.loadStats();

            t.loadData();

            t.getMapData();

            if (window.MAP) {
                window.MAP.setZoom(19 - t.range);
            }

        },

        onPeopleChange() {

            let t = this;

            t.pager.page = 1;

            t.loadStats();

            t.loadData();

            t.getMapData();
        },

        onPeople2Change() {

            let t = this;

            t.loadChartData(t.mp.id);

        },

        saveConfig() {

            let t = this;

            let config = {};

            if (localStorage['analysis_task_config'])
                config = JSON.parse(localStorage['analysis_task_config']) || {};

            config[t.task.id] = {
                filter: t.filter,
                people: t.people,
                startDate: t.startDate.format('yyyy-MM-dd'),
                endDate: t.endDate.format('yyyy-MM-dd'),
                range: t.range,
                colSorters: t.colSorters,
                pager: t.pager
            };

            localStorage['analysis_task_config'] = JSON.stringify(config);

        },

        loadConfig() {

            let t = this;

            let config = {};

            if (localStorage['analysis_task_config'])
                config = JSON.parse(localStorage['analysis_task_config']) || {};

            let c = config[t.task.id];

            if (c) {

                let filterConfig = c.filter;

                if (filterConfig.type && filterConfig.type.length > 0) {
                    t.list.type.forEach(item => {
                        if (filterConfig.type.indexOf(item.value) > -1) {
                            item.selected = true;
                        }
                        else {
                            item.selected = false;
                        }
                    });
                }

                if (filterConfig.room && filterConfig.room.length > 0) {
                    t.list.bedroom.forEach(item => {
                        if (filterConfig.room.indexOf(item.value) > -1) {
                            item.selected = true;
                        }
                        else {
                            item.selected = false;
                        }
                    });
                }

                mirror(filterConfig, t.filter);

                t.range = c.range;
                t.people = c.people;
                t.startDate = new Date(c.startDate);
                t.endDate = new Date(c.endDate);
                t.defaultDates = [t.startDate, t.endDate];
                mirror(c.pager, t.pager);
                mirror(c.colSorter, t.colSorters);
            }
        },

        switchTab(name) {

            let t = this;

            if (t.tab != name) {

                t.tab = name;

                if (name == 'details') {

                    if (t.task) {

                        t.loadConfig();

                        t.confirmFilter();

                        t.loadStats();
                    }
                    else {
                        A.err('リストからタスクをクリックしてください');
                        t.switchTab('list');
                    }
                }

            }

        },

        loadTaskList() {
            let t = this;

            axios.get('monitorTask').then(res => {
                if (res.data.status == 1) {
                    t.list.task = res.data.options.items;

                    // t.viewTask(t.list.task[0]);
                }
            });

        },

        editTask(item) {

            let t = this;

            mirror2(item, t.f);

            t.f.id = item.id;

            t.switchTab('create');

        },

        delTask(item) {

            let t = this;

            axios.delete('monitorTask/' + item.id).then(res => {
                if (res.data.status == 1) {
                    t.list.task.remove(item);
                }
            });

        },

        viewTask(item) {

            let t = this;

            t.task = item;

            t.switchTab('details');

            if (window.MAP) {
                window.MAP.setCenter(new google.maps.LatLng(Number(t.task.lat), Number(t.task.lng)));
                window.MAP.setZoom(19 - t.range);
            }

        },

        createTask() {

            let t = this;


            let f = t.f;

            if (!f.name) {
                A.err('タスク名を入力してください')
                return;
            }

            if (!f.address) {
                A.err('住所を入力してください')
                return;
            }

            if (!f.lat || !f.lng) {
                A.err('緯度経度は純粋な数字のみ入力可能です。<br>例：35.01108225104089, 135.74170991781975')
                return;
            }

            if (f.id) {

                axios.put('monitorTask/' + t.f.id, t.f).then(res => {
                    if (res.data.status == 1) {

                        t.f = {
                            name: '',
                            address: '',
                            lat: '',
                            lng: '',
                            source: 1
                        };

                    }
                });

            }
            else {
                axios.post('monitorTask', t.f).then(res => {
                    if (res.data.status == 1) {

                        t.f = {
                            name: '',
                            address: '',
                            lat: '',
                            lng: '',
                            source: 1
                        };
                    }
                });
            }


        },

    },
    data() {
        return {
            mapRender: {
                mode: 'price'
            },
            showMP: false,
            mp: null,
            task: null,
            showDatePopover2: false,
            showDatePopover3: false,
            tab: 'list',
            minDate: new Date('2025/01/25'),
            startDate: null,
            endDate: null,
            startDate2: null,
            endDate2: null,
            today: Date.today(),
            range: 3,
            people: 2,
            people2: 2,
            defaultDates: [],
            defaultDates2: [],
            list: {
                bedroom: [
                ],
                type: [],
                item: [],
                task: []
            },
            dict: {
                type: {}
            },
            stats: {
                self_income_total: 0,
                self_income_day_avg: 0,
                self_occupancy_rate: 0,

                area_avg_price: 0,
                area_occupancy_total: 0,
                area_occupancy_rate: 0,

            },
            colSorters: {
                type: -1,
                room: -1,
                people: -1,
                bed: -1,
                rating: -1,
                reviews: -1,
                price: -1,
                occupancy_rate: -1,
                min_price: -1,
                avg_price: -1,
            },
            filter: {
                room: [],
                type: [],
                reviews: [0, 1100],
                rating: [0, 5],
                people: [2, 21],
                price: [0, 110],
                occupancy_rate: [0, 100],
            },
            showFilterPopover: false,
            pager: {
                total: 0,
                page: 1,
                perParge: 20,
                showPageSize: 10
            },
            f: {
                name: '',
                address: '',
                lat: '',
                lng: '',
                source: 1
            }
        };
    },
    computed: {
    }
};
