import Vue from 'vue'
import Router from 'vue-router'

import home from './home/home'
import read from './read/read'
import form from './form/form'
import v2 from './v2/v2'
import cc from './cc/cc'
import monitor from './monitor/monitor'
import signup from './signup/signup'
import signin from './signin/signin'
import a4Form from './a4form/a4form'
import cleaner from './cleaner/cleaner'
import report from './report/report'
import schedule from './schedule/schedule'
import pl_cleaner from './pl_cleaner/pl_cleaner'
import business_form from './business_form/business_form'
import business_form_1009 from './business_form_1009/business_form_1009'
import all_stocks from './all_stocks/all_stocks'
import sup_tasks from './sup_tasks/sup_tasks'
import employee from './employee/employee'
import rooms from './room/list'
import analysis from './analysis/analysis'

import './style.css'

Vue.use(Router)
export default new Router({
  base: location.href.indexOf('localhost:8000') > -1 ? '/' : '/report',
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.lastPosition = savedPosition;
      return savedPosition
    } else {
      window.lastPosition = {
        x: 0,
        y: 0
      };
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [{
      path: '',
      redirect: '/signIn'
    },
    {
      name: 'working_report',
      path: '/working_report',
      component: home,
    },
    {
      name: 'read',
      path: '/read',
      component: read,
    },
    {
      name: 'form',
      path: '/form',
      component: form,
    },
    {
      name: 'a4form',
      path: '/a4form',
      component: a4Form,
    },
    {
      name: 'monitor',
      path: '/monitor',
      component: monitor,
    },
    {
      name: 'cc',
      path: '/cc',
      component: cc,
    },
    {
      name: 'v2',
      path: '/v2',
      component: v2,
    },
    {
      name: 'signup',
      path: '/signup',
      component: signup,
    },
    {
      name: 'signin',
      path: '/signin',
      component: signin,
    },
    {
      name: 'cleaner',
      path: '/cleaner',
      component: cleaner,
    },
    {
      name: 'employee',
      path: '/employee',
      component: employee,
    },
    {
      name: 'report',
      path: '/report',
      component: report,
    },
    {
      name: 'schedule',
      path: '/schedule',
      component: schedule,
    },
    {
      name: 'pl_cleaner',
      path: '/pl_cleaner',
      component: pl_cleaner,
    },
    {
      name: 'pl_employee',
      path: '/pl_employee',
      component: pl_cleaner,
    },
    {
      name: 'business_form',
      path: '/business_form',
      component: business_form,
    },
    {
      name: 'business_form_1009',
      path: '/business_form_1009',
      component: business_form_1009,
    },
    {
      name: 'all_stocks',
      path: '/all_stocks',
      component: all_stocks,
    },
    {
      name: 'sup_tasks',
      path: '/sup_tasks',
      component: sup_tasks,
    },
    {
      name: 'rooms',
      path: '/rooms',
      component: rooms,
    },
    {
      name: 'analysis',
      path: '/analysis',
      component: analysis,
    },
  ],
})