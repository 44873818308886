
import SortPicker from '../components/sort-picker.vue';

export default {
    name: 'room',
    components: {
        'sort-picker': SortPicker
    },
    props: {
        room: {
            type: Object,
            required: false
        },
    },
    created() {

        let t = this;

        if (t.room) {
            mirror2(t.room, t.f);
        }

        t.dict.room = listToDict(t.list.room);

        t.dict.lock = listToDict(t.list.lock);

        t.loadData();

    },
    activated() {

        let t = this;

    },
    methods: {

        loadData() {

            let t = this;

            axios.get('cleanArea').then(res => {
                if (res.data.status == 1) {
                    t.list.cleanArea = res.data.options.items;

                    t.list.cleanArea.forEach(item => {
                        t.f2[item.id] = 0;
                    });

                    if (t.room) {
                        t.room.clean_areas.forEach(item => {
                            t.f2[item.area_id] = item.quantity;
                        });
                    }

                }
            });

            axios.get('getUnusedRoomsAndLocks').then(res => {
                if (res.data.status == 1) {
                    t.list.lock = res.data.options.locks;
                    t.dict.lock = listToDict(t.list.lock);
                    t.list.room = res.data.options.rooms;
                    t.dict.room = listToDict(t.list.room);
                }
            });



        },

        onSubmitInfo() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            let isNew = !t.f.id;

            if (t.f.smart_lock == 1)
                t.f.static_password = 'AUTO';

            axios.post('room/saveInfo', t.f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('Saved.');
                    t.f.id = res.data.options.item.id;
                    if (isNew)
                        t.setView('clean_area');
                }
                else {
                    A.err('Faild.');
                }
            });

        },

        onSubmitCleanArea() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            let data = {
                'id': t.f.id,
                'json': JSON.stringify(t.f2)
            }

            axios.post('room/saveCleanAreas', data).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('Saved.');
                }
                else {
                    A.err('Faild.');
                }
            });

        },

        onConfirmLock(lock) {
            this.f.lock_id = lock.id;
            this.f.admin_password = lock.password;
            this.showLockPicker = false;
        },

        onConfirmRoom(room) {
            this.f.outside_id = room.id;
            this.showRoomPicker = false;
            if (!this.f.name)
                this.f.name = room.name;
        },

        setView(v) {
            if (v == 'clean_area') {
                if (!this.f.id) {
                    A.err('宿情報を更新してから、清掃情報を入力することができます。');
                    return;
                }
            }
            this.view = v;
        }
    },
    data() {
        return {
            showLockPicker: false,
            showRoomPicker: false,
            view: 'info',
            dict: {
                room: {},
                lock: {}
            },
            list: {
                type: [
                    { id: 'A', name: 'A' },
                    { id: 'B', name: 'B' },
                    { id: 'C', name: 'C' },
                    { id: 'D', name: 'D' },
                ],
                room: [
                ],
                lock: [
                ],
                cleanArea: []
            },
            f: {
                id: 0,
                name: '',
                name_en: '',
                outside_id: '',
                lock_id: '',
                max_s: '',
                max_k: '',
                max_q: '',
                max_d: '',
                lock_type: 1,
                type: 'A',
                frontend: '',
                address: '',
                admin_password: '',
                case_password: '',
                static_password: '',
                smart_lock: 1
            },
            f2: {

            }
        };
    },
    computed: {
    }
};
