
import Checkboxes from '../components/checkboxes.vue';
import SearchPicker from '../components/search-picker.vue';
import SortPicker from '../components/sort-picker.vue';

export default {
    name: 'cc',
    components: {
        'sort-picker': SortPicker,
        'search-picker': SearchPicker,
        'checkboxes': Checkboxes
    },
    created() {

        let t = this;

        document.body.classList.add('pc');

        t.month = (new Date()).getMonth() + 1;

        t.today = new Date((new Date()).format('yyyy/MM/dd'));

        axios.get('employee?role=2,3').then(res => {
            if (res.data.status == 1) {
                t.list.employee = res.data.options.items;
                t.dict.employee = listToDict(t.list.employee);
                t.loadData();
            }
        });

        t.addGroup();

        t.hour = (new Date()).getHours();
    },
    activated() {

        let t = this;

    },
    methods: {

        loadTask(group) {

            let t = this;

            //读取今日清扫房间

            let p = {
                '_with': 'room,sup',
                'date': t.currentDate.format('yyyy-MM-dd'),
                '_withNextTask': t.currentDate.format('yyyy-MM-dd')
            };

            if (group)
                p.group_id = group.id;

            axios.get('cleanTask', { params: p }).then(res => {

                if (res.data.status == 1) {

                    let arr = res.data.options.items;

                    t.list.room.clear();

                    arr.forEach(item => {

                        item.showEmployeePopover = false;

                        if (!item.sup) {
                            item.sup = {
                                color: 1,
                                bath: 0,
                                toilet: 0,
                                king: 0,
                                queen: 0,
                                double: 0,
                                single: 0,
                                remark: '',
                                employee_id: null,
                                task_id: item.id
                            };
                            if (item.next_order) {
                                item.sup.king = item.next_order.k || 0;
                                item.sup.queen = item.next_order.q || 0;
                                item.sup.double = item.next_order.d || 0;
                                item.sup.single = item.next_order.s || 0;
                            }
                        }

                        if (item.next_task && !item.next_task.sup) {
                            item.next_task.sup = {
                                color: 1,
                                bath: 0,
                                toilet: 0,
                                king: 0,
                                queen: 0,
                                double: 0,
                                single: 0,
                                remark: '',
                                employee_id: null,
                                task_id: item.next_task.id
                            };

                            if (item.next_task.next_order) {
                                item.next_task.sup.king = item.next_task.next_order.k || 0;
                                item.next_task.sup.queen = item.next_task.next_order.q || 0;
                                item.next_task.sup.double = item.next_task.next_order.d || 0;
                                item.next_task.sup.single = item.next_task.next_order.s || 0;
                            }
                        }

                        if (item.room) {

                            item.room.task = item;

                            if (item.group_id) {
                                if (t.dict.group[item.group_id]) {
                                    t.dict.group[item.group_id].tasks.push(item);
                                }
                            }
                            else {
                                item.room.selected = false;
                                t.list.room.push(item.room);
                            }

                        }
                    });

                    t.dict.room = listToDict(t.list.room);

                    if (group) {
                        t.calcGroup(group);
                    }
                    else {
                        t.list.group.forEach((group, i) => {
                            t.calcGroup(group, !(i == t.list.group.length - 1));
                        });
                    }

                }
            });

        },

        loadData() {

            let t = this;

            let p = {
                '_with': 'employee',
                '_order': 'id-1',
                'date': t.currentDate.format('yyyy-MM-dd')
            };

            axios.get('cleanGroup', { params: p }).then(res => {
                if (res.data.status == 1) {
                    let arr = res.data.options.items;
                    arr.forEach(item => {
                        item.showEmployeePopover = false;
                        item.tasks = [];
                        item.people_num = 0;
                        item.editing = false;
                        item.bath = 0;
                        item.toilet = 0;
                        item.king = 0;
                        item.queen = 0;
                        item.double = 0;
                        item.single = 0;
                    })
                    t.list.group = arr;
                    t.dict.group = listToDict(t.list.group);

                    t.loadTask();
                }
            });


        },

        selectRooms() {
            let t = this;

            t.showRoomsPopup = false;

            let rooms = t.$refs.sortPicker.selection;

            t.group.tasks.clear();

            if (rooms) {
                rooms.forEach(room => {
                    room.selected = true;
                    t.group.tasks.push(room.task);
                });

                t.calcGroup(t.group);
            }

        },

        removeRoom(group, task) {

            let t = this;

            group.tasks.remove(task);

            if (task.group_id) {
                if (task.id) {
                    axios.put('cleanTask/' + task.id, { 'group_id': null }).then(res => {
                        if (res.data.status == 1) {

                        }
                    });
                }
                task.group_id = null;
            }

            task.room.selected = false;

            t.calcGroup(group);

        },

        addGroup() {

            this.list.group.push({
                name: 'グループ',
                editing: false,
                people_num: 0,
                bath: 0,
                toilet: 0,
                king: 0,
                queen: 0,
                double: 0,
                single: 0,
                employee_id: null,
                tasks: []
            });
        },

        removeGroup(group) {

            let t = this;

            if (t.group == group)
                t.group = null;

            A.safety('本当にグループを削除しますか？').then(res => {
                if (res.isConfirmed) {
                    function afterDelete() {
                        group.tasks.forEach(task => {
                            task.room.selected = false;
                            task.group_id = null;
                            if (t.list.room.indexOf(task.room) < -1)
                                t.list.room.push(task.room);
                        });
                        t.list.group.remove(group);
                    }
                    if (group.id) {
                        axios.delete('cleanGroup/' + group.id).then(res => {
                            if (res.data.status == 1) {
                                afterDelete();
                            }
                        });
                    }
                    else {
                        afterDelete();
                    }


                }
            });
        },

        setGroup(item) {

            let t = this;

            t.group = item;

            t.showRoomsPopup = true;

        },

        tapColor(task) {

            task.sup.color++;

            if (task.sup.color >= 3)
                task.sup.color = 1;
        },

        calcGroup(group, dontCalcAll) {

            let t = this;

            group.people_num = 0;
            group.bath = 0;
            group.toilet = 0;
            group.king = 0;
            group.queen = 0;
            group.double = 0;
            group.single = 0;

            group.tasks.forEach(task => {

                if (task.sup) {
                    group.bath += task.sup.bath || 0;
                    group.toilet += task.sup.toilet || 0;
                    group.king += task.sup.king || 0;
                    group.queen += task.sup.queen || 0;
                    group.double += task.sup.double || 0;
                    group.single += task.sup.single || 0;
                }

                if (task.next_task && task.next_task.sup) {
                    group.bath += task.next_task.sup.bath || 0;
                    group.toilet += task.next_task.sup.toilet || 0;
                    group.king += task.next_task.sup.king || 0;
                    group.queen += task.next_task.sup.queen || 0;
                    group.double += task.next_task.sup.double || 0;
                    group.single += task.next_task.sup.single || 0;

                    // if (task.next_task.next_order)
                    //     group.people_num +=
                    //         (task.next_task.next_order.count_audlt + task.next_task.next_order.count_child + task.next_task.next_order.count_baby);
                }

                if (task.next_order)
                    group.people_num +=
                        (task.next_order.count_audlt + task.next_order.count_child + task.next_order.count_baby);

            });

            if (!dontCalcAll) {
                t.calcAllGroup();
            }

        },

        calcAllGroup() {

            let t = this;

            t.stats = {
                people_num: 0,
                left: 0,
                bath: 0,
                toilet: 0,
                king: 0,
                queen: 0,
                double: 0,
                single: 0,
            };

            let s = t.stats;

            t.list.group.forEach(group => {
                s.people_num += group.people_num;
                s.bath += group.bath;
                s.toilet += group.toilet;
                s.king += group.king;
                s.queen += group.queen;
                s.double += group.double;
                s.single += group.single;

                group.tasks.forEach(task => {
                    if (task.status != 3) {
                        s.left++;
                    }
                });
            });
        },

        nextDay() {

            this.currentDate.setDate(this.currentDate.getDate() + 1);
            this.loadData();

        },

        prevDay() {

            this.currentDate.setDate(this.currentDate.getDate() - 1);

            this.loadData();
        },

        saveGroup(group) {

            let t = this;

            let task_sups = [];

            group.tasks.forEach(task => {

                if (task.sup)
                    task_sups.push(task.sup);

                if (task.next_task && task.next_task.sup)
                    task_sups.push(task.next_task.sup);

            });

            let p = {
                info: {
                    id: group.id,
                    name: group.name,
                    employee_id: group.employee_id,
                    date: t.currentDate.format('yyyy/MM/dd')
                },
                task_sups: task_sups
            };

            axios.post('cleanGroup/saveTaskSups', p).then(res => {
                if (res.data.status == 1) {
                    let id = res.data.options.id;
                    group.id = id;
                    group.tasks = [];
                    group.editing = false;
                    t.loadTask(group);
                }
            });


        },

        minusItem(group, sup, key) {
            let v = sup[key];

            if (v == 0) {
                return;
            }

            sup[key]--;
            group[key]--;
        },

        plusItem(group, sup, key) {
            let v = sup[key];

            sup[key]++;

            group[key]++;
        },

        printA3() {
            if (window._printStyleTag) window._printStyleTag.remove();
            let style = "@media print { @page {  size: A3 landscape !important; }}";

            let s = document.createElement("style");
            s.innerHTML = style;
            document.body.appendChild(s);
            window._printStyleTag = s;
            window.print();
        },

        printA4() {
            if (window._printStyleTag) window._printStyleTag.remove();
            let style = "@media print { @page {  size: A4 landscape !important; }}";

            let s = document.createElement("style");
            s.innerHTML = style;
            document.body.appendChild(s);
            window._printStyleTag = s;
            window.print();
        },

    },
    data() {
        return {
            todayStr: Date.today().format('yyyy-MM-dd'),
            hour: 0,
            minDate: new Date('2024/01/01'),
            currentDate: Date.today(),
            today: Date.today(),
            list: {
                employee: [],
                room: [],
                selectedRoom: [],
                cleanTask: [],
                group: []
            },
            dict: {
                employee: {},
                room: {},
                cleanTask: {},
                group: {}
            },
            stats: {
                people_num: 0,
                left: 0,
                bath: 0,
                toilet: 0,
                king: 0,
                queen: 0,
                double: 0,
                single: 0,
            },
            showRoomsPopup: false,
            group: {}
        };
    },
    computed: {
        filteredRooms() {
            let t = this;
            let roomIds = [];

            if (t.group) {
                t.group.tasks.forEach(task => {
                    roomIds.push(task.room_id);
                });
            }

            return t.list.room.filter((item) => {
                if (item.selected) {
                    if (roomIds.indexOf(item.id) > -1)
                        return true;
                    else
                        return false;
                }
                return true;
            });
        },
    }
};
