

export default {
    name: "SimpleCheckboxes",
    props: {
        items: {
            type: Array,
            required: true
        },
        selection: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        onselect: {
            type: Function,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        value: {
            type: Boolean,
            required: false
        },
        textField: {
            type: String,
            required: false,
            default: 'name'
        },
        enabledSearch: {
            type: Boolean,
            required: false,
            default: false
        },
        defaultAllChecked: {
            type: Boolean,
            required: false
        },
        col: {
            type: Number,
            required: false,
            default: 1
        },
    },
    mounted() { },
    created() {
        let t = this;

        // if (t.defaultAllChecked && t.selection.length == 0) {
        //     t.items.forEach(item => {
        //         t.$set(item, '_active', true);
        //         t.selection.push(item);
        //     });
        // }
        t.items.forEach(item => {
            t.$set(item, '_active', false);
            if (t.selection.indexOf(item) > -1) {
                item._active = true;
            }
        });

    },
    activated: function (el) {
    },
    deactivated() {
    },
    methods: {
        confirm() {
            let t = this;

            t.selection.clear();

            t.items.forEach(item => {
                if (item._active) {
                    t.selection.push(item);
                }

            });
            t.onselect(t.selection);
            t.close();
        },
        close() {
            this.$emit('input', false);
        }
    },
    data() {
        return {
            keywords: '',
        };
    },
    computed: {
        filteredItems() {
            let t = this;
            if (t.enabledSearch)
                return t.items.filter((item) => {
                    if (item[t.textField] != undefined) {
                        if (item[t.textField].indexOf(t.keywords) > -1) return true;
                    }
                    return false;
                });
            return t.items;
        },
    }
};
