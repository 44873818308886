
export default {
  name: "SideMenu",
  props: {},
  components: {
  },
  mounted() { },
  created() {
    let t = this;

    let reg = /(monitor|v2|schedule|item|rooms|pl_.+|guests|all_stocks|sup_tasks|cc|analysis)/i;

    let expandConfig = {
      'clean': /v2|schedule/,
      'members': /pl_.+|guests/,
      'item': /all_stocks|sup_tasks/,
    };

    t.$router.beforeEach((to, from, next) => {
      let r = reg.exec(to.path);

      if (r && r.length > 0) {
        t.page = r[1];
        t.showNav = true;

        for (const key in expandConfig) {
          if (expandConfig[key].test(t.page)) {
            t.expanded = key;
            break;
          }
        }
      } else t.showNav = false;

      next();
    });

    let r = reg.exec(t.$router.currentRoute.name);

    if (r && r.length > 0) {
      t.page = r[1];
      t.showNav = true;

      for (const key in expandConfig) {
        if (expandConfig[key].test(t.page)) {
          t.expanded = key;
          break;
        }
      }
    } else t.showNav = false;
  },
  methods: {
    goPage(page) {
      if (page == this.page) return;

      this.page = page;

      this.$router.push(page);
    },
    expandToggle(name) {
      if (this.expanded != name) this.expanded = name;
      else this.expanded = '';
    },
  },
  data() {
    return {
      page: "",
      showNav: false,
      expanded: "",
      showAddRoom: false
    };
  },
};
