
import SortPicker from '../components/sort-picker.vue';

export default {
  name: 'schedule',
  components: {
    'sort-picker': SortPicker
  },
  created() {
    let t = this;

    t.tableScale = localStorage['tableScale'] ? Number(localStorage['tableScale']) : 100;
    t.statusDict = listToDict(t.list.status);

    document.body.classList.add("pc");

    t.month = new Date().getMonth() + 1;

    t.startDate = new Date(new Date().format("yyyy/MM/dd"));
    t.days = 31;
    t.endDate = new Date(
      t.startDate.getFullYear(),
      t.startDate.getMonth(),
      t.startDate.getDate() + 31
    );

    axios.get("employee?role=2,3").then((res) => {
      if (res.data.status == 1) {
        let items = res.data.options.items;
        t.cleanerDict = listToDict(items);

        let selection = [];

        if (localStorage["selected_cleaner_ids"]) {
          selection = localStorage["selected_cleaner_ids"].split(",");
          selection.forEach(id => {
            if (t.cleanerDict[id])
              t.cleanerDict[id].selected = true;
            t.list.selectedCleaner.push(t.cleanerDict[id]);
          });
        }

        t.list.cleaner = items;
        t.loadData();
      }
    });

    let todayText = t.today.format("MM/dd");

    for (let i = 1; i <= t.days; i++) {
      let d = new Date(t.startDate.format("yyyy/MM/dd"));

      d.setDate(d.getDate() + i - 1);

      let date = d.format("MM/dd");

      t.monthGrid[date] = {
        k: 0,
        s: 0,
        suffix: ["日", "月", "火", "水", "木", "金", "土"][d.getDay()],
        is_today: date == todayText,
        day_index: d.getDay(),
        is_past: t.today > d
      };
    }

    function refreshStats() {
      axios.get("order/stats4Dashboard").then((res) => {
        if (res.data.status == 1) {
          t.stats = res.data.options.stats;
        }
      });
    }

    refreshStats();

    setInterval(function () {
      refreshStats();
    }, 1000 * 10);

  },
  activated() {
    let t = this;
  },
  methods: {
    scrollToDate(date) {
      this.$refs.calendar.scrollToDate(date);
    },
    cacheValue(v) {
      localStorage[v] = this[v];
    },
    loadData() {
      let t = this;

      t.inited = false;

      t.roomGrid = {};
      t.checkInDict = {};
      t.checkOutDict = {};
      t.extraTaskDict = {};
      t.roomCheckInDict = {};
      t.ksDict = {};
      t.roomDict = {};

      axios.get("room").then((res) => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;

          arr.forEach((room) => {
            room.selected = false;
            t.roomDict[room.id] = room;
          });

          t.list.room = arr;

          axios.get("group").then((res) => {
            if (res.data.status == 1) {
              let arr = res.data.options.items;

              let selection = [];
              let groupDict = listToDict(arr);

              t.list.selectedGroup = [];

              if (localStorage["selected_group_ids"]) {
                selection = localStorage["selected_group_ids"].split(",");
                selection.forEach(id => {
                  if (groupDict[id])
                    t.list.selectedGroup.push(groupDict[id]);
                });
              }


              arr.forEach((group) => {
                group.rooms = [];
                let roomIds = group.room_ids.split(",");
                roomIds.forEach((roomId) => {
                  group.rooms.push(t.roomDict[roomId]);
                });
              });

              t.list.group = arr;

              let selectedRoomIds = [];

              t.list.selectedGroup.forEach(group => {
                let roomIds = group.room_ids.split(",");
                selectedRoomIds.append(roomIds);
              });

              let p = {
                '_with': 'tasks',
                'date': t.startDate.format("yyyy/MM/dd"),
                'endDate': t.endDate.format("yyyy/MM/dd"),
                'room_id': selectedRoomIds.join(',')
              };

              axios
                .get("order", { params: p })
                .then((res) => {
                  if (res.data.status == 1) {
                    let arr = res.data.options.items;

                    arr.forEach((order) => {
                      let checkInDate = order.checkin_date
                        .replace(/\d{4}[\/-]/, "")
                        .replace("-", "/");
                      if (!t.checkInDict[checkInDate])
                        t.checkInDict[checkInDate] = {};
                      t.checkInDict[checkInDate][order.room_id] = order;

                      if (!t.roomCheckInDict[order.room_id])
                        t.roomCheckInDict[order.room_id] = {};
                      t.roomCheckInDict[order.room_id][checkInDate] = order;

                      if (!t.ksDict[checkInDate])
                        t.ksDict[checkInDate] = { k: 0, s: 0 };

                      if (order.k) {
                        t.ksDict[checkInDate].k += Number(order.k);
                        t.ksTotal.k += Number(order.k);
                      }

                      if (order.s) {
                        t.ksDict[checkInDate].s += Number(order.s);
                        t.ksTotal.s += Number(order.s);
                      }

                      let checkOutDate = order.checkout_date
                        .replace(/\d{4}[\/-]/, "")
                        .replace("-", "/");

                      if (!t.checkOutDict[checkOutDate])
                        t.checkOutDict[checkOutDate] = {};
                      t.checkOutDict[checkOutDate][order.room_id] = order;

                      if (order.tasks) {
                        order.tasks.forEach((task) => {
                          if (task.is_extra == 1) {
                            let cleanDate = task.date
                              .replace(/\d{4}[\/-]/, "")
                              .replace("-", "/");
                            if (!t.extraTaskDict[cleanDate])
                              t.extraTaskDict[cleanDate] = {};
                            t.extraTaskDict[cleanDate][order.room_id] = task;
                          }
                        });
                      }
                    });

                    let dateExtras = res.data.options.date_extras;

                    if (dateExtras)
                      dateExtras.forEach((item) => {
                        if (!t.dateExtraDict[item.room_id])
                          t.dateExtraDict[item.room_id] = {};
                        t.dateExtraDict[item.room_id][item.date] = item;
                      });

                    t.initRoomGrid();
                  }
                });
            }
          });
        }
      });
    },

    onSelectStartDate(d) {
      let t = this;

      t.currentDate = d;

      t.startDate = d;

      t.endDate = new Date(
        t.startDate.getFullYear(),
        t.startDate.getMonth(),
        t.startDate.getDate() + 31
      );

      t.days = 31;

      let todayText = t.today.format("MM/dd");

      t.monthGrid = {};

      for (let i = 1; i <= t.days; i++) {
        let d = new Date(t.startDate.format("yyyy/MM/dd"));

        d.setDate(d.getDate() + i - 1);

        let date = d.format("MM/dd");

        t.monthGrid[date] = {
          k: 0,
          s: 0,
          suffix: ["日", "月", "火", "水", "木", "金", "土"][d.getDay()],
          is_today: date == todayText,
          day_index: d.getDay(),
          is_past: t.today > d
        };
      }

      t.loadData();

      t.showDatePopover2 = false;
    },

    refreshDateItem(gridItem) {
      let t = this;

      gridItem.bg_color = "bg-pink";

      if (gridItem.clean_status == 99) {
        //无清扫员
        gridItem.bg_color = "bg-pink";
      } else if (gridItem.clean_status == 1) {
        //有清扫员
        let cleanDeadline = new Date(
          gridItem.checkout_date + " 13:00:00"
        ).valueOf();
        let now = new Date().valueOf();
        if (now > cleanDeadline) {
          gridItem.clean_delayed = true;
          gridItem.bg_color = "bg-red";
        } else gridItem.bg_color = "bg-pink";
      } else if (gridItem.clean_status == 2) {
        //正在清扫
        gridItem.bg_color = "bg-yellow";
      } else if (gridItem.clean_status == 3) {
        //清扫完成
        gridItem.bg_color = "bg-green";
      }

      let d = new Date(t.today.getFullYear() + "/" + gridItem.date);
      let d2 = new Date(t.today.format("yyyy/MM/dd"));

      d2.setDate(d2.getDate() + 3);

      if (
        gridItem.clean_status == 99 &&
        d2 > d &&
        d >= new Date(t.today.format("yyyy/MM/dd"))
      ) {
        gridItem.bg_color = "bg-orange";
      }

      if (gridItem.checkin_date) {
        d = new Date(gridItem.checkin_date);
        d2 = new Date(t.today.getFullYear() + "/" + gridItem.date);

        d = d.format("yyyy/MM/dd");
        d2 = d2.format("yyyy/MM/dd");

        if (d == d2 && gridItem.override_clean_status == 3) {
          gridItem.bg_color = "bg-green";
        }
      }
    },

    refreshContinuous(gridItem, grid) {
      let t = this;

      let checkInItem = gridItem.checkInItem;

      if (checkInItem && checkInItem.continuous_days > 1) {
        let room = t.roomDict[gridItem.room_id];

        let date = new Date(checkInItem.checkin_date).format("MM/dd");

        for (let i = 1; i < checkInItem.continuous_days; i++) {
          let d = new Date(date);

          d.setDate(d.getDate() + i);

          let date2 = d.format("MM/dd");

          if (!grid[date2]) grid[date2] = {};

          let dateItem = grid[date2][room.id];

          if (!dateItem)
            dateItem = grid[date2][room.id] = {
              room_name: room.name,
              room_id: room.id,
              date: date2,
              id: null,
              cleaner_firstname: "",
              cleaner_lastname: "",
              cleaner_id: 0,
              source: "",
              status: 99,
              bg_color: "bg-grey",
              continuous_days: 0,
              is_continuous: false,
              is_last_continuous: false,
              cid: null,
              form_status: 1,
              emergency_status: 0,
            };

          dateItem.sourceItem = checkInItem;
          dateItem.is_continuous = true;
          dateItem.is_last_continuous = i == checkInItem.continuous_days - 1;
          dateItem.bg_color = gridItem.bg_color + "2";
          dateItem.sourceCheckInItem = gridItem.checkInItem;

          if (t.extraTaskDict[date2] && t.extraTaskDict[date2][room.id]) {
            let task = t.extraTaskDict[date2][room.id];
            if (task.cleaner_id) {
              dateItem.task_id = task.id;
              dateItem.cleaner_id = task.cleaner_id;
              dateItem.cleaner_firstname =
                t.cleanerDict[task.cleaner_id].firstname;
              dateItem.clean_status = task.status;
              dateItem.cleaner_lastname =
                t.cleanerDict[task.cleaner_id].lastname;
            }
            t.refreshDateItem(dateItem);
          }
        }
      }
    },

    initRoomGrid() {
      let t = this;
      let grid = {};

      function fillGrid() {
        t.list.group.forEach((group) => {
          group.rooms.forEach((room) => {
            for (let j = -15; j <= t.days + 15; j++) {
              let d = new Date(t.startDate.format("yyyy/MM/dd"));

              d.setDate(d.getDate() + j - 1);

              let date = d.format("MM/dd");

              if (!grid[date]) grid[date] = {};

              let gridItem = grid[date][room.id];

              if (!gridItem)
                gridItem = grid[date][room.id] = {
                  room_name: room.name,
                  room_id: room.id,
                  date: date,
                  id: null,
                  cleaner_firstname: "",
                  cleaner_lastname: "",
                  cleaner_id: 0,
                  // source: '',
                  status: 99,
                  bg_color: "bg-grey",
                  continuous_days: 0,
                  is_continuous: false,
                  is_last_continuous: false,
                  cid: null,
                  form_status: 1,
                  emergency_status: 0,
                };

              if (t.checkInDict[date] && t.checkInDict[date][room.id]) {
                let checkInItem = {};

                checkInItem.date = date;

                // checkInItem.bg_color = 'bg-pink';

                mirror(t.checkInDict[date][room.id], checkInItem);

                checkInItem.bell_color = "fill-white";

                if (checkInItem.form_status == 1)
                  checkInItem.bell_color = "fill-red";

                if (
                  new Date(t.today.getFullYear() + "/" + date).valueOf() <
                  t.today.valueOf()
                )
                  checkInItem.bell_color = "fill-outline";

                t.refreshDateItem(checkInItem);

                gridItem.checkInItem = checkInItem;
              }

              if (t.checkOutDict[date] && t.checkOutDict[date][room.id]) {
                let checkOutItem = {};

                checkOutItem.date = date;

                mirror(t.checkOutDict[date][room.id], checkOutItem);

                t.refreshDateItem(checkOutItem);

                gridItem.checkOutItem = checkOutItem;
              }

              if (gridItem.checkInItem) {
                gridItem.checkInItem.room_name = gridItem.room_name;

                gridItem.checkInItem.room_id = gridItem.room_id;

                gridItem.checkInItem.dateInGrid = date;
              }

              if (gridItem.checkInItem) {
                let checkInItem = gridItem.checkInItem;

                gridItem.bg_color = checkInItem.bg_color;

                if (checkInItem.continuous_days > 1) {
                  for (let i = 1; i < checkInItem.continuous_days; i++) {
                    let d = new Date(date);

                    d.setDate(d.getDate() + i);

                    let date2 = d.format("MM/dd");

                    if (!grid[date2]) grid[date2] = {};

                    let dateItem = grid[date2][room.id];

                    if (!dateItem)
                      dateItem = grid[date2][room.id] = {
                        room_name: room.name,
                        room_id: room.id,
                        date: date2,
                        id: null,
                        cleaner_firstname: "",
                        cleaner_lastname: "",
                        cleaner_id: 0,
                        source: "",
                        status: 99,
                        bg_color: "bg-grey",
                        continuous_days: 0,
                        is_continuous: false,
                        is_last_continuous: false,
                        cid: null,
                        form_status: 1,
                        emergency_status: 0,
                      };

                    dateItem.sourceItem = checkInItem;
                    dateItem.is_continuous = true;
                    dateItem.is_last_continuous =
                      i == checkInItem.continuous_days - 1;
                    dateItem.bg_color = gridItem.bg_color + "2";
                    dateItem.continuous_bg_color = gridItem.bg_color + "2";

                    if (
                      t.extraTaskDict[date2] &&
                      t.extraTaskDict[date2][room.id]
                    ) {
                      let task = t.extraTaskDict[date2][room.id];
                      if (task.cleaner_id) {
                        dateItem.cleaner_id = task.cleaner_id;
                        dateItem.cleaner_firstname =
                          t.cleanerDict[task.cleaner_id].firstname;
                        dateItem.cleaner_lastname =
                          t.cleanerDict[task.cleaner_id].lastname;
                      }
                      t.refreshDateItem(dateItem);
                    }
                  }
                }

                t.refreshContinuous(gridItem, grid);
              }

              if (gridItem.checkOutItem) {
                let checkOutItem = gridItem.checkOutItem;
                gridItem.bg_color = checkOutItem.bg_color;
                t.refreshContinuous(gridItem, grid);
              }
            }
          });
        });

        t.list.group.forEach((group) => {
          group.rooms.forEach((room) => {
            for (let j = -15; j <= t.days + 15; j++) {
              let d = new Date(t.startDate.format("yyyy/MM/dd"));

              d.setDate(d.getDate() + j - 1);

              let date = d.format("MM/dd");

              if (!grid[date]) grid[date] = {};

              let gridItem = grid[date][room.id];

              if (gridItem.checkOutItem && !gridItem.checkInItem) {
                let minDateVal = 0;
                let minDate = "";
                let dateVal = new Date(
                  t.today.getFullYear() + "/" + date
                ).valueOf();

                for (const date2 in t.roomCheckInDict[room.id]) {
                  let date2Val = new Date(
                    t.today.getFullYear() + "/" + date2
                  ).valueOf();

                  if (date2Val > dateVal) {
                    if (minDateVal == 0) {
                      minDateVal = date2Val;
                      minDate = date2;
                    } else if (date2Val < minDateVal) {
                      minDateVal = date2Val;
                      minDate = date2;
                    }
                  }
                }

                if (minDate) {
                  if (
                    grid[minDate] &&
                    grid[minDate][room.id] &&
                    grid[minDate][room.id].checkInItem
                  ) {
                    gridItem.nextCheckInItem =
                      grid[minDate][room.id].checkInItem;
                    gridItem.nextCheckInGridItem = grid[minDate][room.id];

                    if (gridItem.nextCheckInItem) {
                      gridItem.nextCheckInItem.cleaned_at = date;
                      gridItem.nextCheckInItem.bg_color = gridItem.bg_color;
                      gridItem.nextCheckInGridItem.bg_color = gridItem.bg_color;

                      let checkInItem = gridItem.nextCheckInItem;
                      if (checkInItem.continuous_days > 1) {
                        for (let i = 1; i < checkInItem.continuous_days; i++) {
                          let d = new Date(checkInItem.date);

                          d.setDate(d.getDate() + i);

                          let date2 = d.format("MM/dd");

                          if (!grid[date2]) grid[date2] = {};

                          let dateItem = grid[date2][room.id];

                          dateItem.sourceItem = checkInItem;
                          dateItem.is_continuous = true;
                          dateItem.is_last_continuous =
                            i == checkInItem.continuous_days - 1;
                          dateItem.bg_color = gridItem.bg_color + "2";
                          dateItem.continuous_bg_color =
                            gridItem.bg_color + "2";

                          if (
                            t.extraTaskDict[date2] &&
                            t.extraTaskDict[date2][room.id]
                          ) {
                            let task = t.extraTaskDict[date2][room.id];
                            if (task.cleaner_id) {
                              dateItem.cleaner_id = task.cleaner_id;
                              dateItem.cleaner_firstname =
                                t.cleanerDict[task.cleaner_id].firstname;
                              dateItem.cleaner_lastname =
                                t.cleanerDict[task.cleaner_id].lastname;
                            }
                            t.refreshDateItem(dateItem);
                          }
                        }
                      }

                      t.refreshContinuous(
                        gridItem.nextCheckInGridItem,
                        t.roomGrid
                      );
                    }

                    let fullDate = new Date(
                      t.today.getFullYear() + "/" + gridItem.date
                    ).format("yyyy-MM-dd");

                    if (
                      t.dateExtraDict[room.id] &&
                      t.dateExtraDict[room.id][fullDate]
                    ) {
                      let dateExtra = t.dateExtraDict[room.id][fullDate];
                      gridItem.dateExtra = dateExtra;
                    }
                  }
                }
              }
            }
          });
        });
      }

      fillGrid();

      t.roomGrid = grid;

      t.inited = true;
    },

    printA3() {
      if (window._printStyleTag) window._printStyleTag.remove();
      // transform: scale(0.9) !important;
      let style = "@media print { @page {  size: A3 landscape !important; } .room-rows { zoom:0.9;   transform-origin: left top; } }";

      let s = document.createElement("style");
      s.innerHTML = style;
      document.body.appendChild(s);
      window._printStyleTag = s;
      window.print();
    },

    printA4() {
      if (window._printStyleTag) window._printStyleTag.remove();
      let style = "@media print { @page {  size: A4 landscape !important; } .room-rows { zoom:0.62;  transform-origin: left top; } }";

      let s = document.createElement("style");
      s.innerHTML = style;
      document.body.appendChild(s);
      window._printStyleTag = s;
      window.print();
    },

    refreshSelection() {
      let t = this;
      let arr = [];

      let res = t.$refs.sortPicker.selection;

      if (res.length > 0) {

        res.forEach(item => {
          arr.push(item.id);
          item.selected = true;
        });
        localStorage["selected_cleaner_ids"] = arr.join(",");
      }

      t.list.selectedCleaner.clear();
      t.list.selectedCleaner.append(res);
      t.$forceUpdate();


    },

    refreshGroupSelection() {
      let t = this;
      let arr = [];

      let res = t.$refs.sortPicker2.selection;

      if (res.length > 0) {

        res.forEach(item => {
          arr.push(item.id);
        });
        localStorage["selected_group_ids"] = arr.join(",");
      }

      t.list.selectedGroup.clear();
      t.list.selectedGroup.append(res);
      t.loadData();
      t.$forceUpdate();
    },

    editCleaner(date, room_id) {

      let t = this;

      let gridItem = t.roomGrid[date][room_id];

      if (!gridItem.checkOutItem)
        return;

      // if (t.roomGrid[date][room_id].sourceCheckInItem)
      // gridItem = t.roomGrid[date][room_id].sourceCheckInItem;

      // else
      //  if (t.roomGrid[date][room_id].checkOutItem)
      // gridItem = t.roomGrid[date][room_id].checkOutItem;

      // else if (t.roomGrid[date][room_id].checkInItem)
      //     gridItem = t.roomGrid[date][room_id].checkInItem;

      // else if (t.roomGrid[date][room_id].nextCheckInItem)
      //     gridItem = t.roomGrid[date][room_id].nextCheckInItem;

      t.editingItem = gridItem;

      t.editingItem.cid = gridItem.checkOutItem.cleaner_id;

      t.selectedCleaner = t.cleanerDict[gridItem.checkOutItem.cleaner_id];

      t.selectedStatus = t.statusDict[gridItem.checkOutItem.clean_status];

      t.showCleanerEditor = true;
    },

    saveCleaner() {
      let t = this;

      let gridItem = t.editingItem;

      let cid;

      if (t.selectedCleaner == -1)
        cid = null;
      else if (t.selectedCleaner)
        cid = t.selectedCleaner.id;

      if (t.busying)
        return;

      t.busying = true;

      let f = { cleaner_id: cid };

      if (t.editingItem.checkOutItem && t.editingItem.checkOutItem.id) {
        f.id = t.editingItem.checkOutItem.id;
        f.clean_status = 1;
        if (t.selectedStatus)
          f.clean_status = t.selectedStatus.id;
      }
      else if (gridItem.is_continuous) {
        f.date = (new Date(t.today.getFullYear() + '/' + gridItem.date)).format('yyyy/MM/dd');
        f.room_id = gridItem.room_id;
        f.order_id = gridItem.sourceCheckInItem.id;
        f.status = 1;
        f.clean_status = 1;
      }

      axios.post('order/setCleaner', f).then(res => {

        t.busying = false;

        if (res.data.status == 1) {
          if (cid) {
            if (gridItem.is_continuous) {

              gridItem.checkOutItem.cleaner_id = cid;
              gridItem.checkOutItem.clean_status = f.clean_status;
              gridItem.checkOutItem.cleaner_firstname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].firstname;
              gridItem.checkOutItem.cleaner_lastname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].lastname;

              t.refreshDateItem(gridItem, true);
            }
            else {
              gridItem.checkOutItem.cleaner_id = cid;
              gridItem.checkOutItem.clean_status = f.clean_status;
              gridItem.checkOutItem.cleaner_firstname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].firstname;
              gridItem.checkOutItem.cleaner_lastname = t.cleanerDict[gridItem.checkOutItem.cleaner_id].lastname;

              t.refreshDateItem(gridItem.checkOutItem, true);
              gridItem.bg_color = gridItem.checkOutItem.bg_color;

              if (gridItem.nextCheckInItem) {
                gridItem.nextCheckInItem.bg_color = gridItem.bg_color;
                gridItem.nextCheckInGridItem.bg_color = gridItem.bg_color;
                t.refreshContinuous(gridItem.nextCheckInGridItem, t.roomGrid);
              }
            }


          }
          else {
            if (gridItem.is_continuous) {

              gridItem.checkOutItem.cleaner_id = null;
              gridItem.checkOutItem.clean_status = 99;
              gridItem.checkOutItem.cleaner_firstname = null;
              gridItem.checkOutItem.cleaner_lastname = null;

              gridItem.checkOutItem.bg_color = gridItem.checkOutItem.continuous_bg_color;
            }
            else {
              gridItem.checkOutItem.cleaner_id = null;
              gridItem.checkOutItem.clean_status = 99;
              gridItem.checkOutItem.cleaner_firstname = null;
              gridItem.checkOutItem.cleaner_lastname = null;

              t.refreshDateItem(gridItem.checkOutItem, true);
              gridItem.bg_color = gridItem.checkOutItem.bg_color;
            }
          }

          t.refreshContinuous(gridItem, t.roomGrid);

          t.showCleanerEditor = false;
        }
      });
    },
  },
  data() {
    return {
      tableScale: 100,
      showCalendar: false,
      minDate: new Date("2024/01/01"),
      currentDate: new Date(new Date().format("yyyy/MM/dd")),
      endDate: new Date(new Date().format("yyyy/MM/dd")),
      today: new Date(new Date().format("yyyy/MM/dd")),
      days: 31,
      selectedCleaner: null,
      selectedStatus: null,
      showDatePopover2: false,
      showGroupsEditor: false,
      showCleanersEditor: false,
      editingItem: null,
      showCleanerEditor: false,
      showCleanerPopover: false,
      showStatusPopover: false,
      month: 2,
      stats: {
        unfill_form: 0,
        today_unclean: 0,
        today_emergency_appointment: 0,
      },
      list: {
        status: [
          { id: 1, text: "未開始" },
          { id: 2, text: "清掃中" },
          { id: 3, text: "完　了" },
        ],
        room: [],
        group: [],
        cleaner: [],
        selectedCleaner: [],
        selectedGroup: []
      },
      inited: false,
      monthGrid: {},
      roomGrid: {},
      checkInDict: {},
      checkOutDict: {},
      extraTaskDict: {},
      roomCheckInDict: {},
      statusDict: {},
      ksDict: {},
      ksTotal: { k: 0, s: 0 },
      roomDict: {},
      cleanerDict: {},
      dateExtraDict: {},
    };
  },
  computed: {
    filteredRooms() {
      let t = this;
      let arr = [];

      let ids = [];

      t.list.group.forEach((group) => {
        if (t.editingGroup && t.editingGroup.id == group.id) return;
        if (group.rooms) {
          group.rooms.forEach((room) => {
            ids.push(room.id);
          });
        }
      });

      t.list.room.forEach((room) => {
        if (ids.indexOf(room.id) < 0) arr.push(room);
      });

      return arr;
    },
  },
};
