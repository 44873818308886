

export default {
  name: "ColSorter",
  props: {
    config: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    activeColor: {
      type: String,
      required: false,
      default: '#fff'
    },
    inactiveColor: {
      type: String,
      required: false,
      default: '#000'
    }
  },
  mounted() { },
  created() {
    let t = this;
  },
  activated: function (el) {
  },
  deactivated() {
  },
  methods: {
    tap() {

      let t = this;

      if (t.config[t.name] == -1) {
        t.config[t.name] = 1;
      }
      else if (t.config[t.name] == 1) {
        t.config[t.name] = 2;
      }
      else if (t.config[t.name] == 2) {
        t.config[t.name] = -1;
      }

      for (const name in t.config) {
        if (name != t.name)
          t.config[name] = -1;
      }

      t.$emit('change', [t.name, t.config[t.name]]);

    }
  },
  data() {
    return {
    };
  },
};
